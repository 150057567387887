import React, { useRef, useState } from 'react';
import { ProGallery, GALLERY_CONSTS } from 'pro-gallery';
import * as classes from './Gallery.scss';
import Section from '../Section';
import {
  GalleryArrowPositionOptions,
  GalleryItemSizeOptions,
  GalleryLayoutOptions,
  ImageResizeOptions,
  SectionTypes,
} from '../../types';
import {
  useSettings,
  useStyles,
} from 'yoshi-flow-editor-runtime/tpa-settings/react';
import { useEnvironment } from 'yoshi-flow-editor-runtime';
import { useDimensions } from '../useDimensions';
import settingsParams from '../../settingsParams';
import stylesParams from '../../stylesParams';
import imageSDK from 'image-client-api/dist/imageClientSDK';
import { GallerySectionViewModel } from '../../../../service-page-view-model/gallery-section-view-model/gallerySectionViewModel';
import { useSizeListener } from '../useSizeListener';

type GalleryComponentProps = {
  viewModel: GallerySectionViewModel;
};

const Gallery: React.FC<GalleryComponentProps> = ({ viewModel }) => {
  const widgetDimensions = useDimensions();
  const { isMobile, isEditor, isPreview, isEditorX } = useEnvironment();
  const settings = useSettings();
  const styles = useStyles();
  const containerRef = useRef<HTMLDivElement>(null);
  const containerDimensions = useSizeListener(containerRef);
  const selectedLayout: GalleryLayoutOptions = settings.get(
    settingsParams.galleryLayout,
  );
  const [galleryHeight, setGalleryHeight] = useState(0);
  const isSliderImageResizeOptionCrop =
    settings.get(settingsParams.gallerySliderImageResizeOption) ===
    ImageResizeOptions.CROP;

  const getGalleryDimensions = () => {
    if (!containerDimensions || !widgetDimensions) {
      return;
    }

    return {
      height:
        selectedLayout === GalleryLayoutOptions.SLIDER
          ? settings.get(settingsParams.gallerySliderHeight)
          : containerDimensions.width,
      width: containerDimensions.width,
    };
  };

  const galleryItemSizeOptionsToPixels = (
    galleryItemSizeOptions: GalleryItemSizeOptions,
  ) => {
    if (isMobile) {
      return 200;
    }
    switch (galleryItemSizeOptions) {
      case GalleryItemSizeOptions.LARGE:
        return 500;
      case GalleryItemSizeOptions.SMALL:
        return 150;
      case GalleryItemSizeOptions.MEDIUM:
      default:
        return 300;
    }
  };

  const getOptionsByGalleryLayoutOptions = () => {
    const isShowArrows =
      viewModel.items.length > 1 &&
      settings.get(settingsParams.gallerySliderShowArrows);
    switch (selectedLayout) {
      case GalleryLayoutOptions.MASONRY:
        return {
          galleryLayout: GALLERY_CONSTS.layout.MASONRY,
          itemBorderColor: styles.get(
            stylesParams.galleryMasonryItemBorderColor,
          ).value,
          itemBorderWidth: settings.get(
            settingsParams.galleryMasonryItemBorderWidth,
          ),
          itemBorderRadius: settings.get(
            settingsParams.galleryMasonryItemRadius,
          ),
          imageMargin: settings.get(settingsParams.galleryMasonryItemSpacing),
          gallerySizeType: 'px',
          gallerySizePx: galleryItemSizeOptionsToPixels(
            settings.get(settingsParams.galleryMasonryItemSize),
          ),
          hoveringBehaviour: GALLERY_CONSTS.infoBehaviourOnHover.NEVER_SHOW,
        };
      case GalleryLayoutOptions.GRID:
        return {
          galleryLayout: GALLERY_CONSTS.layout.GRID,
          itemBorderColor: styles.get(stylesParams.galleryGridItemBorderColor)
            .value,
          imageMargin: settings.get(settingsParams.galleryGridItemSpacing),
          itemBorderWidth: settings.get(
            settingsParams.galleryGridItemBorderWidth,
          ),
          itemBorderRadius: settings.get(settingsParams.galleryGridItemRadius),
          gallerySizeType: 'px',
          gallerySizePx: settings.get(settingsParams.galleryGridThumbnailSize),
          hoveringBehaviour: GALLERY_CONSTS.infoBehaviourOnHover.NEVER_SHOW,
        };
      case GalleryLayoutOptions.SLIDER:
      default:
        return {
          galleryLayout: GALLERY_CONSTS.layout.SLIDER,
          itemBorderColor: styles.get(stylesParams.gallerySliderItemBorderColor)
            .value,
          imageMargin: settings.get(settingsParams.gallerySliderItemSpacing),
          itemBorderWidth: settings.get(
            settingsParams.gallerySliderItemBorderWidth,
          ),
          itemBorderRadius: settings.get(
            settingsParams.gallerySliderItemRadius,
          ),
          cubeType: GALLERY_CONSTS.cubeType.CROP,
          slideshowLoop: settings.get(settingsParams.gallerySliderLoopImages),
          isAutoSlideshow:
            !isEditor && settings.get(settingsParams.gallerySliderAutoSlide),
          autoSlideshowInterval: settings.get(
            settingsParams.gallerySliderTimeBetweenImages,
          ),
          showArrows: isShowArrows,
          arrowsSize: settings.get(settingsParams.gallerySliderArrowsSize),
          arrowsPosition:
            settings.get(settingsParams.gallerySliderArrowsPosition) ===
              GalleryArrowPositionOptions.INSIDE || !isShowArrows
              ? GALLERY_CONSTS.arrowsPosition.ON_GALLERY
              : GALLERY_CONSTS.arrowsPosition.OUTSIDE_GALLERY,
          hoveringBehaviour: GALLERY_CONSTS.infoBehaviourOnHover.NEVER_SHOW,
          cubeRatio: isSliderImageResizeOptionCrop ? '1' : '100%/100%',
        };
    }
  };

  const getViewMode = () => {
    // if (isViewer) {
    //   return GALLERY_CONSTS.viewMode.SITE;
    // }
    if (isPreview) {
      return GALLERY_CONSTS.viewMode.PREVIEW;
    }
    if (isEditorX || isEditor) {
      return GALLERY_CONSTS.viewMode.EDIT;
    }
  };

  const items = viewModel.items.map(
    ({ height, width, relativeUri, altText }, i) => ({
      itemId: `sample-id-${i}`,
      mediaUrl: relativeUri,
      metaData: {
        alt: altText,
        type: 'image',
        height,
        width,
      },
    }),
  );

  return items.length ? (
    <Section sectionType={SectionTypes.GALLERY}>
      <div
        className={classes.root}
        style={{ height: galleryHeight }}
        ref={containerRef}
      >
        <div
          data-hook="gallery-section"
          className={classes.galleryWrapper}
          style={{ height: galleryHeight }}
        >
          {widgetDimensions && containerDimensions ? (
            <SafeGallery
              items={items}
              options={getOptionsByGalleryLayoutOptions()}
              container={getGalleryDimensions()}
              viewMode={getViewMode()}
              onGalleryDimensionsChanged={({ height }) => {
                setGalleryHeight(height);
              }}
            />
          ) : null}
        </div>
      </div>
    </Section>
  ) : null;
};

class SafeGallery extends React.Component<
  {
    items;
    options;
    container;
    onGalleryDimensionsChanged;
    viewMode;
  },
  { hasError }
> {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  componentDidCatch() {
    this.setState({ hasError: true });
  }

  render() {
    if (this.state.hasError) {
      return undefined;
    }
    const {
      items,
      options,
      container,
      onGalleryDimensionsChanged,
      viewMode,
    } = this.props;
    return (
      <ProGallery
        key={JSON.stringify({ ...options, ...container })}
        domId="service-page-gallery-section"
        items={items}
        viewMode={viewMode}
        options={options}
        container={container}
        eventsListener={(eName, eData) => {
          if (eName === GALLERY_CONSTS.events.GALLERY_CHANGE) {
            onGalleryDimensionsChanged({
              height: eData.layoutHeight,
              width: 0,
            });
          }
        }}
        resizeMediaUrl={(item, url, resizeMethod, width, height) => {
          return imageSDK.getScaleToFillImageURL(
            url,
            item.metaData.width,
            item.metaData.height,
            width,
            height,
          );
        }}
      />
    );
  }
}

export default Gallery;
