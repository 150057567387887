import React, { useRef } from 'react';
import { useEnvironment } from 'yoshi-flow-editor-runtime';
import { useSettings } from 'yoshi-flow-editor-runtime/tpa-settings/react';
import { Text, TYPOGRAPHY } from 'wix-ui-tpa/Text';
import { HeroImage, FocalPointPresets } from 'wix-ui-tpa/HeroImage';
import { HeaderViewModel } from '../../../../service-page-view-model/header-view-model/headerViewModel';
import { ImagePositionOptions, MainComponents } from '../../types';
import { RenderLocationProvider } from '../useRenderLocation';
import { st, classes } from './Header.st.css';
import settingsParams from '../../settingsParams';
import BookButton from '../BookButton/BookButton';
import { useSizeListener } from '../useSizeListener';

type HeaderProps = {
  viewModel: HeaderViewModel;
  className?: string;
};

const Header: React.FC<HeaderProps> = ({ viewModel, className }) => {
  const settings = useSettings();
  const headerRef = useRef<HTMLDivElement>(null);
  const { isMobile, isRTL } = useEnvironment();
  const imagePositionToFocalPoint = {
    [ImagePositionOptions.BOTTOM]: FocalPointPresets.bottomCenter,
    [ImagePositionOptions.BOTTOM_LEFT]: FocalPointPresets.bottomLeft,
    [ImagePositionOptions.BOTTOM_RIGHT]: FocalPointPresets.bottomRight,
    [ImagePositionOptions.LEFT]: FocalPointPresets.centerLeft,
    [ImagePositionOptions.RIGHT]: FocalPointPresets.centerRight,
    [ImagePositionOptions.TOP]: FocalPointPresets.centerTop,
    [ImagePositionOptions.TOP_LEFT]: FocalPointPresets.topLeft,
    [ImagePositionOptions.TOP_RIGHT]: FocalPointPresets.topRight,
    [ImagePositionOptions.MIDDLE]: FocalPointPresets.center,
  };

  const headerDimensions = useSizeListener(headerRef);

  const serviceImage = () =>
    settings.get(settingsParams.headerImageVisibility) &&
    viewModel?.image &&
    headerDimensions.width &&
    headerDimensions.height ? (
      <div className={classes.media}>
        <HeroImage
          key={`${JSON.stringify(
            settings.get(settingsParams.headerImagePosition),
          )} ${headerDimensions.height} ${headerDimensions.width}`}
          src={viewModel.image?.relativeUri || ''}
          width={headerDimensions.width}
          height={headerDimensions.height}
          alt={viewModel.image?.altText}
          focalPoint={
            imagePositionToFocalPoint[
              settings.get(settingsParams.headerImagePosition)
            ]
          }
          className={classes.image}
        />
      </div>
    ) : null;

  const serviceTitle = () =>
    settings.get(settingsParams.headerTitleVisibility) ? (
      <Text
        data-hook="header-title"
        className={st(classes.title, { isRTL }, className)}
        typography={TYPOGRAPHY.largeTitle}
        tagName={settings.get(settingsParams.headerTitleHtmlTag)}
      >
        {viewModel?.title}
      </Text>
    ) : null;

  const bookButton = () =>
    settings.get(settingsParams.headerBookButtonVisibility) &&
    viewModel.isBookable ? (
      <BookButton dataHook="header-book-button" renderedAt="HEADER" />
    ) : null;

  return (
    <RenderLocationProvider value={MainComponents.HEADER}>
      <header
        className={st(
          classes.root,
          {
            alignment: settings.get(settingsParams.headerTitleAlignment),
            isMobile,
            isRTL,
          },
          className,
        )}
        aria-label={viewModel?.title}
        data-hook="header-wrapper"
        ref={headerRef}
      >
        {serviceImage()}
        {serviceTitle()}
        {bookButton()}
      </header>
    </RenderLocationProvider>
  );
};

export default Header;
